
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    data: {
      type: Object,
      required: true,
    },
    src: {
      type: String,
      default: "",
    },
  },
});
