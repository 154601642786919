<template>
  <div class="news">
    <div class="page-1"></div>
    <div class="news-type pag flex-row">
      <span
        v-for="ty in types"
        :key="ty.type"
        class="flex-row flex-center cur"
        @click.stop="type = ty.id"
        :class="{ type: type == ty.id }"
      >
        {{ ty.name }}
      </span>
    </div>
    <div class="page-3 pag flex-col py-2">
      <news-child class="my-3" v-for="data in datas" :key="data" :data="data" />
    </div>
  </div>
</template>

<script lang="ts">
import NewsChild from "@/components/NewsChild.vue";
import { computed, defineComponent, ref } from "vue";

export default defineComponent({
  components: { NewsChild },
  name: "News",
  setup() {
    const types = [
      { name: "行业动态", id: "industry" },
      { name: "平台动态", id: "platform" },
    ];
    const type = ref("industry");
    const datas = computed(() => {
      const industry = [
        {
          head: "大标题1",
          txt: "文本文本文本文本文本",
          date: "2021-8-12",
        },
        {
          head: "大标题2",
          txt: "文本文本文本文本文本",
          date: "2021-8-12",
        },
        {
          head: "大标题3",
          txt: "文本文本文本文本文本",
          date: "2021-8-12",
        },
        {
          head: "大标题4",
          txt: "文本文本文本文本文本",
          date: "2021-8-12",
        },
        {
          head: "大标题5",
          txt: "文本文本文本文本文本",
          date: "2021-8-12",
        },
      ];
      const platform: any[] = [];
      return type.value == "industry" ? industry : platform;
    });
    return {
      type,
      types,
      datas,
    };
  },
});
</script>

<style lang="scss" scoped>
.news {
  width: 100%; ///;
  font-family: PingFangSC-Semibold, PingFang SC;
  .page-1 {
    width: 100%; ///;
    height: 20.833vw;
    background: linear-gradient(
      176deg,
      rgba(77, 120, 231, 0) 0%,
      rgba(36, 63, 131, 0.1) 46%,
      rgba(36, 63, 131, 0.1) 46%,
      #051c50 75%,
      #001034 100%
    );
    background-image: url(~@/assets/image/page1.png);
    background-size: 100% auto;
    background-position: center;
  }
  .pag {
    padding: 0 13.802vw;
    background: #f8f8f8;
  }
  .news-type {
    font-size: 1.667vw;
    font-weight: 600;
    color: #0a193e;
    background: #f8f8f8;
    height: 5.208vw;
    box-shadow: 0vw 0.521vw 1.563vw 0vw rgba(171, 204, 226, 0.3);
    span {
      height: 5vw;
      &.type {
        border-bottom: 0.2vw solid #5ca4f1;
        transform: scale(1);
      }
      margin: 0 0.5vw 0 0;
      transform: scale(0.7);
    }
  }
  .page-3 {
    height: 60vh;
    overflow-y: auto;
    margin: 0.5vw 0;
  }
}
</style>
