<template>
  <div class="flex-row news-child over-hidd flex-shrink">
    <div class="news-child-img flex-shrink p-r flex-col flex-center">
      <slot name="src">
        <el-image class="w-100 h-100 p-a" :src="src" lazy></el-image>
      </slot>
    </div>
    <div class="flex-col p-r news-child-text w-100">
      <div class="p-a w-100 h-100 flex-col">
        <span class="date-box">{{ data.date }}</span>
        <p class="flex-row head-box over-hidd">
          {{ data.head }}
        </p>
        <span class="text-box">{{ data.txt }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    data: {
      type: Object,
      required: true,
    },
    src: {
      type: String,
      default: "",
    },
  },
});
</script>

<style lang="scss" scoped>
.news-child {
  padding: 1vw 1.25vw;
  background-color: #fff;
  border-radius: 0.8vw;
  z-index: 0;
  .news-child-img {
    height: 9.375vw;
    width: 16.667vw;
    // background-color: #999;
    border-radius: 0.417vw;
    border: 1px solid rgba(211, 211, 211, 0.4);
    // background-size: 100% 100%;
  }
  .news-child-text {
    margin-left: 0.75vw;
    .head-box {
      height: 1.719vw;
      font-size: 1.25vw;
      font-weight: 600;
      color: #0a193e;
      line-height: 1.719vw;
      margin: 0.417vw 0 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      display: -moz-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
    .text-box {
      margin-top: 1.25vw;
      height: 3.35vw;
      font-size: 0.833vw;
      font-weight: 400;
      color: #0a193e;
      line-height: 1.146vw;
      display: -webkit-box;
      display: -moz-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }
    .date-box {
      width: 100%;
      text-align: right;
      font-size: 0.5vw;
      color: rgba(56, 55, 55, 0.5);
    }
  }
}
</style>
