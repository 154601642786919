
import NewsChild from "@/components/NewsChild.vue";
import { computed, defineComponent, ref } from "vue";

export default defineComponent({
  components: { NewsChild },
  name: "News",
  setup() {
    const types = [
      { name: "行业动态", id: "industry" },
      { name: "平台动态", id: "platform" },
    ];
    const type = ref("industry");
    const datas = computed(() => {
      const industry = [
        {
          head: "大标题1",
          txt: "文本文本文本文本文本",
          date: "2021-8-12",
        },
        {
          head: "大标题2",
          txt: "文本文本文本文本文本",
          date: "2021-8-12",
        },
        {
          head: "大标题3",
          txt: "文本文本文本文本文本",
          date: "2021-8-12",
        },
        {
          head: "大标题4",
          txt: "文本文本文本文本文本",
          date: "2021-8-12",
        },
        {
          head: "大标题5",
          txt: "文本文本文本文本文本",
          date: "2021-8-12",
        },
      ];
      const platform: any[] = [];
      return type.value == "industry" ? industry : platform;
    });
    return {
      type,
      types,
      datas,
    };
  },
});
